<template lang="pug">
    .settings__inputs-wrap
        .settings__group(v-for="(career, index) in careers" v-if="!career.deleted")
            .settings__item
                label {{ locale("company-name") }}
                input(v-model="career.company" :class="{invalid: $v.careers.$each[index].company.$error}" @keyup="getCompanies($event, career)")
                transition-expand
                    .settings__company-helper(v-if="career.companies.length")
                        .settings__company-helper-inner
                            .settings__company-helper-title {{ locale("search-title") }}
                            .settings__company-helper-list
                                .settings__company-helper-item(v-for="company in career.companies" :key="company.data.hid" @click="selectCompany(career, company.value)") {{ company.value }}
                                    span.settings__company-helper-item-inn {{ company.data.address.value }}
                .input-error(v-if="$v.careers.$each[index].company.$error") {{ locale("company-name-error") }}
            .settings__item
                label {{ locale("company-position") }}
                input(v-model="career.position" :class="{invalid: $v.careers.$each[index].position.$error}")
                .input-error(v-if="$v.careers.$each[index].company.$error") {{ locale("company-position-error") }}
            .settings__item
                label {{ locale("company-start") }}
                date-picker(
                    v-model="career.start_at",
                    type="year",
                    prefix-class="merlinface",
                    valueType="YYYY-MM-DD",
                    format="DD.MM.YYYY",
                    :default-value="career.start_at || new Date()",
                    value-type="date",
                    clearable=false
                    :class="{invalid: $v.careers.$each[index].start_at.$error}"
                )
                .input-error(v-if="$v.careers.$each[index].company.$error") {{ locale("company-start-error") }}
            .settings__item
                label {{ locale("company-end") }}
                date-picker(
                    v-model="career.end_at",
                    type="year",
                    prefix-class="merlinface",
                    valueType="YYYY-MM-DD",
                    format="DD.MM.YYYY",
                    :default-value="career.end_at || new Date()",
                    value-type="date",
                    clearable=false
                )
                .settings__help-text {{ locale("company-end-now") }}
            .settings__item(v-if="showCtrl")
                ui-button(type="bordered" :text="locale('delete')" color="gray" @click.native="career.deleted = true")
        .settings__item
            ui-button(@click.native="saveData" :text="saveText")
        .settings__item(v-if="showCtrl")
            ui-button(@click.native="addCareer" type="bordered" :text="locale('add')")
        .settings__item
            transition-expand
                span.settings__time-stamp(v-if="isSaved") {{ locale("saved") }}
</template>

<script>
import gql from "graphql-tag";
import personEditCareer from "@/graphql/mutations/personEditCareer.mutation.graphql";
import DatePicker from "vue2-datepicker";
import UiButton from "@/components/ui/ui-button/ui-button";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import { required } from "vuelidate/lib/validators";

const DADATA_TOKEN = process.env.VUE_APP_DADATA_API_KEY;

export default {
    name: "CareerSettingsForm",
    components: { TransitionExpand, UiButton, DatePicker },
    props: {
        person: {
            type: Object,
            required: true,
            default: () => {},
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        showCtrl: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            careers: [],
            isSaved: false,
            companiesTimeout: null,
        };
    },
    computed: {
        saveText: (state) =>
            `${state.locale("save")}${
                !state.showCtrl ? state.locale("andContinue") : ""
            }`,
        careersToSave: function () {
            const c = [];
            this.careers.forEach((career) => {
                if (career.deleted) {
                    c.push({
                        id: career.id,
                        position: null,
                        company: null,
                        start_at: null,
                        end_at: null,
                    });
                } else {
                    if (career.company) {
                        c.push(career);
                    }
                }
            });
            return c;
        },
    },
    validations() {
        if (this.required) {
            return {
                careers: {
                    $each: {
                        position: {
                            required,
                        },
                        company: {
                            required,
                        },
                        start_at: {
                            required,
                        },
                    },
                },
            };
        } else {
            return {
                careers: {
                    $each: {
                        position: {},
                        company: {},
                        start_at: {},
                    },
                },
            };
        }
    },
    created() {
        this.person.careers.forEach((career) => {
            this.careers.push({
                ...career,
                companies: [],
                deleted: false,
            });
        });

        if (!this.careers.length) {
            this.addCareer();
        }
    },
    methods: {
        async saveData() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return false;
            }

            let savedCareersCount = 0;

            this.careersToSave.forEach((career, index) => {
                this.$apollo
                    .mutate({
                        mutation: gql(personEditCareer),
                        variables: {
                            personId: this.myPerson.uuid,
                            input: {
                                id: Number(career.id),
                                position: career.position,
                                company: career.company,
                                start_at: career.start_at,
                                end_at: career.end_at,
                            },
                        },
                    })
                    .then((r) => {
                        savedCareersCount++;
                        career.id = r.data.personEditCareer[index].id;
                        if (savedCareersCount >= this.careersToSave.length) {
                            this.$emit("update");
                        }
                    });
            });

            if (!this.careersToSave.length) {
                this.$emit("update");
            }

            this.isSaved = true;
            setTimeout(() => {
                this.isSaved = false;
            }, 4000);
        },
        addCareer() {
            this.careers.push({
                id: 1,
                position: null,
                company: null,
                start_at: null,
                end_at: null,
                companies: [],
                deleted: false,
            });
        },
        getCompanies(event, career) {
            console.log(event, career);
            const query = event.target.value;

            clearTimeout(this.companiesTimeout);
            this.companiesTimeout = null;

            if (!query) {
                career.companies = [];
                return;
            }

            this.companiesTimeout = setTimeout(() => {
                let url =
                    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
                let options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Token " + DADATA_TOKEN,
                    },
                    body: JSON.stringify({ query, count: 10 }),
                };

                fetch(url, options)
                    .then(async (response) => {
                        const companies = await response.json();
                        career.companies = companies.suggestions;
                    })
                    .catch((error) => console.log("error", error));
            }, 500);
        },
        selectCompany(career, companyName) {
            career.company = companyName;
            career.companies = [];
        },
    },
};
</script>
