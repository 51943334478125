<template lang="pug">
    .settings__inputs-wrap(@click="getCountries")
        .settings__group(v-for="(education, index) in educations" v-if="!education.deleted")
            .settings__item
                label  {{ locale("country") }}
                multiselect._input(v-model="education.country" :searchable="false" :options="countries" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('selectCountry')" @select="searchCity('', education)")

            .settings__item
                label  {{ locale("city") }}
                multiselect._input(v-model="education.city" :id="education" :disabled="!education.country" :options="education.cities" :searchable="true" :loading="education.isCityLoading" @search-change="searchCity" @select="searchPlace('', education)" label="title" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="!education.country ? locale('alreadySelectCountry') : locale('searchCity')")
                    span(slot="noResult")  {{ locale("no-cities") }}

            .settings__item
                label  {{ locale("education-place") }}
                multiselect._input(v-model="education.institution" :id="education" :disabled="!education.city" :options="education.places" :searchable="true" :loading="education.isPlaceLoading" @search-change="searchPlace" label="title" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="!education.city ? locale('alreadySelectCity') : ''")
                    span(slot="noResult")  {{ locale("education-place-nothing") }}
                .input-error(v-if="$v.educations.$each[index].institution.$error")  {{ locale("education-palce-error") }}

            .settings__item
                label  {{ locale("education-position") }}
                input(v-model="education.speciality" :class="{invalid: $v.educations.$each[index].speciality.$error}")
                .input-error(v-if="$v.educations.$each[index].speciality.$error")  {{ locale("education-position-error") }}

            .settings__item
                label  {{ locale("education-start") }}
                date-picker(
                    v-model="education.start_at",
                    type="year",
                    prefix-class="merlinface",
                    valueType="YYYY",
                    format="YYYY",
                    :default-value="education.start_at || new Date()",
                    value-type="date",
                    clearable=false
                    :class="{invalid: $v.educations.$each[index].start_at.$error}"
                )
                .input-error(v-if="$v.educations.$each[index].start_at.$error")  {{ locale("education-start-error") }}
            .settings__item
                label  {{ locale("education-end") }}
                date-picker(
                    v-model="education.end_at",
                    type="year",
                    prefix-class="merlinface",
                    valueType="YYYY",
                    format="YYYY",
                    :default-value="education.end_at || new Date()",
                    value-type="date",
                    clearable=false
                )
                .settings__help-text  {{ locale("education-end-now") }}
            .settings__item(v-if="showCtrl")
                ui-button(type="bordered" :text="locale('delete')" color="gray" @click.native="education.deleted = true")
        .settings__item
            ui-button(@click.native="saveData" :text="saveText")
        .settings__item(v-if="showCtrl")
            ui-button(@click.native="addEducation" type="bordered" :text="locale('add')")
        .settings__item
            transition-expand
                span.settings__time-stamp(v-if="isSaved && required")  {{ locale("saved") }}
</template>

<script>
import gql from "graphql-tag";
import personEditEducationMutation from "@/graphql/mutations/personEditEducation.mutation.graphql";
import getCountriesListQuery from "@/graphql/queries/getCountriesList.query.graphql";
import DatePicker from "vue2-datepicker";
import UiButton from "@/components/ui/ui-button/ui-button";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import { required } from "vuelidate/lib/validators";
import multiselect from "vue-multiselect/src";
import jsonp from "jsonp";

export default {
    name: "EducationSettingsForm",
    components: { TransitionExpand, UiButton, DatePicker, multiselect },
    props: {
        person: {
            type: Object,
            required: true,
            default: () => {},
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        showCtrl: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            educations: [],
            countries: [],
            isSaved: false,
        };
    },
    computed: {
        saveText: (state) =>
            `${state.locale("save")}${
                !state.showCtrl ? state.locale("andContinue") : ""
            }`,
        educationsToSave: function () {
            const c = [];
            this.educations.forEach((education) => {
                if (education.deleted) {
                    c.push({
                        id: education.id,
                        country: null,
                        city: null,
                        speciality: null,
                        institution: null,
                        start_at: null,
                        end_at: null,
                    });
                } else {
                    if (education.institution) {
                        c.push(education);
                    }
                }
            });
            return c;
        },
    },
    validations() {
        if (this.required) {
            return {
                educations: {
                    $each: {
                        institution: {
                            required,
                        },
                        speciality: {
                            required,
                        },
                        start_at: {
                            required,
                        },
                    },
                },
            };
        } else {
            return {
                educations: {
                    $each: {
                        institution: {},
                        speciality: {},
                        start_at: {},
                    },
                },
            };
        }
    },
    created() {
        this.getCountries();
        this.person.educations.forEach(async (education) => {
            const edu = {
                ...education,
                cities: [],
                places: [],
                deleted: false,
                isCityLoading: false,
                isPlaceLoading: false,
                timeout: null,
                country: null,
                city: null,
            };
            await this.getCountryById(education.country_id, edu);
            await this.getCityById(education.city_id, edu);
            await this.searchPlace(edu.institution, edu, true);
            this.educations.push(edu);
        });

        if (!this.person.educations.length) {
            console.log("add empty", this.person.educations.length);
            this.addEducation();
        }
    },
    methods: {
        async saveData() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return false;
            }

            let savedEducationsCount = 0;

            await this.educationsToSave.forEach((education) => {
                this.$apollo
                    .mutate({
                        mutation: gql(personEditEducationMutation),
                        variables: {
                            personId: this.myPerson.uuid,
                            input: {
                                id: Number(education.id),
                                speciality: education.speciality,
                                institution:
                                    education.institution?.title || null,
                                country_id: education.country?.id
                                    ? +education.country.id
                                    : null,
                                city_id: education.city?.id || null,
                                start_at: education.start_at
                                    ? new Date(new Date(education.start_at).getFullYear(), 1, 1)
                                    : null,
                                end_at: education.end_at
                                    ? new Date(new Date(education.end_at).getFullYear(), 1, 1)
                                    : null,
                            },
                        },
                    })
                    .then((r) => {
                        savedEducationsCount++;
                        education.id = r.data.personEditEducation[0].id;
                        if (
                            savedEducationsCount >= this.educationsToSave.length
                        ) {
                            this.$emit("update");
                        }
                    })
                    .catch((e) => {
                        console.log(e.response);
                    });
            });

            if (!this.educationsToSave.length) {
                this.$emit("update");
            }

            this.isSaved = true;
            setTimeout(() => {
                this.isSaved = false;
            }, 4000);
        },
        addEducation() {
            this.educations.push({
                id: 1,
                speciality: null,
                institution: null,
                start_at: null,
                end_at: null,
                country: null,
                city: null,
                cities: [],
                places: [],
                deleted: false,
                isCityLoading: false,
                isPlaceLoading: false,
                timeout: null,
            });
        },
        searchCity(q, place) {
            place.isCityLoading = true;

            clearTimeout(place.timeout);
            place.timeout = setTimeout(() => {
                const url = this.generateUrl(
                    "database.getCities",
                    `country_id=${place.country.id}&q=${q}`,
                );

                jsonp(url, null, (err, data) => {
                    place.cities = data.response.items;
                    place.isCityLoading = false;
                });
            }, 500);
        },
        searchPlace(q, place, set = false) {
            place.isPlaceLoading = true;
            place.places = [];

            clearTimeout(place.timeout);
            place.timeout = setTimeout(() => {
                const url = this.generateUrl(
                    "database.getUniversities",
                    `country_id=${place.country.id}&city_id=${place.city.id}&q=${q}`,
                );
                const url2 = this.generateUrl(
                    "database.getSchools",
                    `&city_id=${place.city.id}&q=${q}`,
                );

                jsonp(url2, null, (err, data) => {
                    data.response.items.map((s) => {
                        const school = s;
                        school.title = s.title.replace(/№ /, "");
                        place.places.push(school);
                    });
                    if (set) {
                        place.institution = data.response.items[0];
                    }
                    place.isPlaceLoading = false;
                });

                jsonp(url, null, (err, data) => {
                    place.places.push(...data.response.items);
                    if (set) {
                        if (place.institution) return;
                        place.institution = data.response.items[0];
                    }
                });
            }, 500);
        },
        generateUrl(method, params) {
            const lang = this.$i18n.locale || "ru";
            return `https://api.vk.com/method/${method}?${params}&access_token=${process.env.VUE_APP_VK_SERVICE_KEY}&v=5.131&lang=${lang}`;
        },
        async getCountries() {
            await this.$apollo
                .query({
                    query: gql(getCountriesListQuery),
                })
                .then((r) => {
                    this.countries = r.data.getCountriesList;
                });
        },
        getCityById(id, edu) {
            const url = this.generateUrl(
                "database.getCitiesById",
                `city_ids=${id}`,
            );
            return jsonp(url, null, (e, data) => {
                edu.city = data.response[0];
            });
        },
        getCountryById(id, edu) {
            const url = this.generateUrl(
                "database.getCountriesById",
                `country_ids=${id}`,
            );

            return jsonp(url, null, (e, data) => {
                console.log(data.response[0]);
                edu.country = {
                    id: String(data.response[0].id),
                    name: String(data.response[0].title)
                };
            });
        },
    },
};
</script>
