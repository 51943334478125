<template lang="pug">
  .settings__inputs-wrap
    .settings__item
      label {{ locale("user_like") }}
      input(v-model="user_like" @blur="saveData")
    .settings__item
      label {{ locale("user_good") }}
      input(v-model="user_good" @blur="saveData")
    .settings__item
      label {{ locale("user_usually") }}
      input(v-model="user_usually" @blur="saveData")
    .settings__item
      label {{ locale("user_work") }}
      input(v-model="user_work" @blur="saveData")
    .settings__item
      label {{ locale("user_goal") }}
      input(v-model="user_goal" @blur="saveData")
    .settings__item
      label {{ locale("user_skill") }}
      input(v-model="user_skill" @blur="saveData")
    .settings__item
      span.settings__time-stamp(v-if="isSaved && required") {{ locale("saved") }}
    .settings__item(v-if="!required")
        ui-button(text="Продолжить" @click.native="saveDataClick")._btn
</template>

<script>
import gql from "graphql-tag";
import personEditAboutMutation from "@/graphql/mutations/personEditAbout.mutation.graphql";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "AboutSettingsForm",
    components: { UiButton },
    props: {
        person: {
            type: Object,
            required: true,
            default: () => {},
        },
        required: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            user_like: "",
            user_good: "",
            user_usually: "",
            user_work: "",
            user_goal: "",
            user_skill: "",
            isSaved: false,
        };
    },
    created() {
        const about = this.person.about;
        this.user_like = about.user_like;
        this.user_good = about.user_good;
        this.user_usually = about.user_usually;
        this.user_work = about.user_work;
        this.user_goal = about.user_goal;
        this.user_skill = about.user_skill;
    },
    methods: {
        async saveData() {
            if (!this.required) return;
            await this.$apollo
                .mutate({
                    mutation: gql(personEditAboutMutation),
                    variables: {
                        personId: this.myPerson.uuid,
                        input: {
                            user_like: this.user_like,
                            user_good: this.user_good,
                            user_usually: this.user_usually,
                            user_work: this.user_work,
                            user_goal: this.user_goal,
                            user_skill: this.user_skill,
                        },
                    },
                })
                .then(() => {
                    this.isSaved = true;
                    this.$emit("update");
                    setTimeout(() => {
                        this.isSaved = false;
                    }, 4000);
                });
        },
        async saveDataClick() {
            await this.$apollo
                .mutate({
                    mutation: gql(personEditAboutMutation),
                    variables: {
                        personId: this.myPerson.uuid,
                        input: {
                            user_like: this.user_like,
                            user_good: this.user_good,
                            user_usually: this.user_usually,
                            user_work: this.user_work,
                            user_goal: this.user_goal,
                            user_skill: this.user_skill,
                        },
                    },
                })
                .then(() => {
                    this.isSaved = true;
                    this.$emit("update");
                    setTimeout(() => {
                        this.isSaved = false;
                    }, 4000);
                });
        },
    },
};
</script>
